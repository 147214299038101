import Vue from 'vue';
import Router from 'vue-router';

Vue.use( Router );

const appRouter = new Router({
    mode: 'history',
    routes: [
        // generated-routes
        {
            path: '/',
            name: 'Choose',
            component: () =>
            {
                return import( /* webpackChunkName: "choose" */ '@/app/pages/Choose' );
            }
        },
        {
            path: '/consument',
            name: 'Consumer',
            component: () =>
            {
                return import( /* webpackChunkName: "consumer" */ '@/app/pages/Consumer' );
            }
        },
        {
            path: '/professional',
            name: 'Professional',
            component: () =>
            {
                return import( /* webpackChunkName: "professional" */ '@/app/pages/Professional' );
            }
        }
    ],
    scrollBehavior( to, from )
    {
        return { x: 0, y: 0 };
    }
});

appRouter.beforeEach( ( to, from, next ) =>
{
    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default appRouter;
